<template>
  <div>
    <hr>
    <b-row>
      <b-col cols="3">
        <b-col
            sm="10"
            class="d-flex justify-content-center"
        >
          <!-- apex chart -->
          <vue-apex-charts
              type="radialBar"
              height="270"
              :options="supportTrackerRadialBar.uretilen"
              :series="supportTrackerRadialBar.uretilen_series"
          />
        </b-col>
      </b-col>
      <b-col cols="3">
        <vue-apex-charts
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.tuketilen"
            :series="supportTrackerRadialBar.tuketilen_series"
        />
      </b-col>
      <b-col cols="6"></b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="ApertureIcon"
            statistic="0 kt"
            color="warning"
            statistic-title="CO₂ Reduction"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="GridIcon"
            color="success"
            statistic="1,410"
            statistic-title="Günlük Üretilen(kWh)"
        />
      </b-col>

      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="ChevronDownIcon"
            color="danger"
            statistic="0"
            statistic-title="Günlük Tüketilen(kWh)"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="DollarSignIcon"
            color="primary"
            statistic="2.820"
            statistic-title="Günlük Gelir(TL)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="SunIcon"
            statistic="95%"
            color="warning"
            statistic-title="Anlık Performans"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="LayersIcon"
            color="success"

            statistic="79.200"
            statistic-title="Toplam Üretilen(mWh)"
        />
      </b-col>

      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="ChevronsDownIcon"
            color="danger"
            statistic="0"
            statistic-title="Toplam Tüketilen(mWh)"
        />
      </b-col>
      <b-col
          lg="3"
          sm="6"
      >
        <statistic-card-horizontal
            icon="DatabaseIcon"
            color="primary"
            statistic="155.210"
            statistic-title="Toplam Gelir(TL)"
        />
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="6">
        <apex-line-area-chart fff="uretim" title="Üretim" />
      </b-col>
      <b-col cols="6">
        <apex-line-area-chart fff="tuketim" title="Tüketim" />
      </b-col>
    </b-row>

  </div>
</template>

<script>

import { BCol, BRow } from 'bootstrap-vue'
import VueApexCharts from "vue-apexcharts";
import {$themeColors} from "@themeConfig";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import ApexLineAreaChart from "@/views/custom_app/customer/dashboard/components/ApexLineAreaChart.vue";

export default {
  components: {
    ApexLineAreaChart,
    StatisticCardHorizontal,
    VueApexCharts,
    BRow,
    BCol,
  },
  data() {
    return {
      supportTrackerRadialBar: {
        uretilen_series: [95],
        tuketilen_series: [0.5],
        uretilen: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Üretilen Alık Güç'],
        },
        tuketilen: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Tüketilen Alık Güç'],
        },
      },
    }
  },
  created() {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker';
@import '@core/scss/vue/libs/chart-apex';
</style>
