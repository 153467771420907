<template>
  <div>
    <hr>
    <b-row>
      <b-col cols="6" class="border p-50">
        <app-timeline>
          <app-timeline-item
              title="Performans Hatası (AI)"
              subtitle="Panel temizliği sonrasında performansta %4'lük bir artış öngörülmektedir."
              icon="CpuIcon"
              time="1 Saat Önce"
              variant="danger"/>
          <app-timeline-item
              title="Performans Hatası (AI)"
              subtitle="Inverter değişimi önerilmektedir."
              icon="CpuIcon"
              time="2 Saat Önce"
              variant="warning"/>
        </app-timeline>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BCol, BRow } from 'bootstrap-vue'
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    AppTimelineItem, AppTimeline,
    BRow,
    BCol,
  },
  data() {
    return {
    }
  },
  created() {

  },
}
</script>

<style lang="scss">
</style>
