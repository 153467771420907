<template>
  <div>
    <b-card no-body>
      <b-card-header class="p-50">
        <div>
          <b-badge v-b-tooltip="'Merkez Adı'" class="mr-25" variant="primary"><feather-icon icon="TrelloIcon" class="mr-50"/><span class="align-middle">{{ plant.center_name }}</span></b-badge>
          <b-badge v-b-tooltip="'Konum'" class="mr-25" variant="secondary"><feather-icon icon="MapPinIcon" class="mr-50"/><span class="align-middle">{{ plant.country.name }} - {{ plant.states.name }} - {{ plant.cities.name }}</span></b-badge>
          <b-badge v-b-tooltip="'Alış Fiyatı'" class="mr-25" variant="danger"><feather-icon icon="TrendingDownIcon" class="mr-50"/><span class="align-middle">{{ plant.purchase_price }} TL</span></b-badge>
          <b-badge v-b-tooltip="'Satış Fiyatı'" class="mr-25" variant="info"><feather-icon icon="TrendingUpIcon" class="mr-50" /><span class="align-middle">{{ plant.sale_price }} TL</span></b-badge>
        </div>
        <div>
          <b-badge v-b-tooltip="'Sıcaklık'" class="mr-25" variant="warning"><feather-icon icon="ThermometerIcon" class="mr-50"/><span class="align-middle">20 °C</span></b-badge>
          <b-badge v-b-tooltip="'Hava Durumu'" class="mr-25" variant="warning"><feather-icon icon="SunIcon"/></b-badge>
          <b-badge v-b-tooltip="'Nem'" class="mr-25" variant="warning"><feather-icon icon="DropletIcon" class="mr-50"/><span class="align-middle">0%</span></b-badge>
          <b-badge v-b-tooltip="'Yağış'" class="mr-25" variant="warning"><feather-icon icon="CloudRainIcon" class="mr-50"/><span class="align-middle">0%</span></b-badge>
          <b-badge v-b-tooltip="'Rüzgar'" class="mr-25" variant="warning"><feather-icon icon="WindIcon" class="mr-50"/><span class="align-middle">0 km/sa</span></b-badge>
          <b-badge v-b-tooltip="'Basınç'" class="mr-25" variant="warning"><feather-icon icon="CrosshairIcon" class="mr-50"/><span class="align-middle">0 hPa</span></b-badge>
          <b-badge v-b-tooltip="'İletişim'" class="mr-25" variant="success">
            <feather-icon
                icon="RssIcon"
                class="mr-50"
            />
            <span class="align-middle">{{ plant.checkDate }} {{new Date().toLocaleString()}}</span>
          </b-badge>
          <b-badge v-b-tooltip="'Seçenekler'" variant="info"><feather-icon icon="SettingsIcon" /></b-badge>
        </div>
      </b-card-header>
      <b-card-body>
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="ActivityIcon" />
              <span>Detay</span>
            </template>
            <plant-detail-analystics />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="CastIcon" />
              <span>Cihazlar</span>
            </template>
            <devices :center_id="plantId" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="SunIcon" />
              <span>Meteoroloji Bilgi Ekranı</span>
            </template>
            <meteoroloji :center_id="plantId" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="AlertTriangleIcon" />
              <span>Uyarı</span>
            </template>
            <uyari-view :center_id="plantId" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="CpuIcon" />
              <span>Yapay Zeka Analizi</span>
              <b-badge class="ml-25" variant="danger">YENİ</b-badge>
            </template>
            <ai-view :center_id="plantId" />
          </b-tab>
<!--          <b-tab>-->
<!--            <template #title>-->
<!--              <feather-icon icon="AlertTriangleIcon" />-->
<!--              <span>Alarmlar</span>-->
<!--            </template>-->
<!--            <alarm />-->
<!--          </b-tab>-->
<!--          <b-tab>-->
<!--            <template #title>-->
<!--              <feather-icon icon="WifiIcon" />-->
<!--              <span>Anlık Veri</span>-->
<!--            </template>-->
<!--            <live-data />-->
<!--          </b-tab>-->
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardText,
  BCardTitle,
  BCol,
  BRow,
  BTab,
  BTabs, VBTooltip,
} from 'bootstrap-vue'
import router from '@/router'
import PlantDetailAnalystics from '@/views/custom_app/components/plants/PlantDetailAnalystics.vue'
import Devices from '@/views/custom_app/components/devices/Devices.vue'
import Alarm from '@/views/custom_app/components/alarm/Alarm.vue'
import LiveData from '@/views/custom_app/components/live-data/LiveData.vue'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from "vue-ripple-directive";
import meteoroloji from "@/views/custom_app/components/plants/Meteoroloji.vue";
import aiView from "@/views/custom_app/components/plants/AiView.vue";
import uyariView from "@/views/custom_app/components/plants/UyariView.vue";
export default {
  components: {
    uyariView,
    meteoroloji,
    aiView,
    LiveData,
    BCardSubTitle,
    BCardHeader,
    BCardTitle,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BTabs,
    BTab,
    PlantDetailAnalystics,
    Devices,
    Alarm,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      plant: {},
      plantId: router.currentRoute.params.id,
    }
  },
  created() {
    useJwt.customerCenterDetail(this.plantId)
      .then(response => {
        this.plant = response.data
      })
      .catch(error => {
        console.log(error.response)
      })
  },
}
</script>

<style lang="scss">
</style>
