<template>
  <div>
    <hr>
    <b-card-body  class="card-transaction">
      <b-table
          responsive
          :items="table_data"
          :fields="tableColumns"
          primary-key="id"
          class="text-nowrap "
          style="min-height: 550px"
          show-empty
          empty-text="Herhangi bir kayıt bulunamadı."
      >
      </b-table>

    </b-card-body>
  </div>
</template>

<script>

import {
  BAvatar,
  BBadge,
  BCard,
  BCardBody,
  BCol,
  BDropdown,
  BDropdownItem,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
  BTable,
} from 'bootstrap-vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import useJwt from '@/auth/jwt/useJwt'
import router from "@/router";

export default {
  components: {
    BTable, BDropdown, BDropdownItem, BLink,
    BCardBody,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BMedia,
    BImg,
    Locale,
    BBadge,
    BCard,
    BCol,
    BRow,
  },
  data() {
    return {
      plantId: router.currentRoute.params.id,
      data: [],
      table_data: [],
      tableColumns: [
        { key: 'eic104_ip_address', label: 'IP Address' },
        { key: 'eic104_port', label: 'Port' },
        // { key: 'common_address', label: 'Common Address' },
        { key: 'event_report_type_id', label: 'Event Report Type' },
        { key: 'io_address', label: 'IO Address' },
        { key: 'value', label: 'Value' },
        { key: 'time_stamp', label: 'Time Stamp' },
      ],
    }
  },
  created() {
    this.get_iec104_data()
  },
  mounted() {
    window.setInterval(() => {
      this.get_iec104_data()
    }, 30000)
  },
  methods: {
    get_iec104_data() {
      const formData = new FormData()
      formData.append('center_id', this.plantId)
      useJwt.getCustomerCenterIec104Data(formData)
        .then(response => {
          this.table_data = response.data.rawData
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
