<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="new_device_add_modal"
      ref="new_device_add_modal"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Yeni Cihaz Ekle"
      footer-class="d-none"
      size="xl"
      @hide="close_new_device_add_modal"
    >
      <div>
        <b-row>
          <b-col
            cols="8"
          >
            <b-form-group
              label="Cihaz Türü"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="devices_tpye"
                :options="devices_tpye_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="devices_tpye.value!==null"
            cols="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Bağlantı Protokolü"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="connection_protocol"
                :options="connection_protocol_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="connection_protocol.value!==null">
          <b-col
            cols="12"
          >
            <b-form-group
              label="Cihaz Marka"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="device_brand"
                :options="device_brand_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="device_brand.value!==null">
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Cihaz Adı"
              label-for="name"
            >
              <b-form-input
                v-model="device_name"
                placeholder="Cihaz Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Cihaz Seri No"
              label-for="name"
            >
              <b-form-input
                v-model="device_serial_no"
                placeholder="Cihaz Seri No"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="device_brand.value!==null">
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Cihaz Açıklama"
              label-for="name"
            >
              <b-form-input
                v-model="device_comment"
                placeholder="Cihaz Açıklama"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="device_brand.value!==null">
          <b-col
            cols="8"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="IP Adresi"
              label-for="ip_address"
            >
              <b-form-input
                v-model="ip_address"
                name="ip_address"
                type="text"
                placeholder="192.168.25.66"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Port"
              label-for="port"
            >
              <b-form-input
                v-model="port"
                name="port"
                placeholder="Port"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="connection_protocol.value=='modbus'"
            cols="2"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Unit ID"
              label-for="unit_id"
            >
              <b-form-input
                v-model="unit_id"
                name="unit_id"
                placeholder="Unit ID"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr v-if="device_brand.value!==null">
        <div v-if="device_brand.value!==null">
          <b-row
            v-for="(register,index) in device_registers"
            :key="index"
            class="align-items-center"
          >
            <b-col cols="1">
              <b-form-group
                label="Register Adı"
                label-for="register_name"
              >
                <b-form-input
                  v-model="register.register_name"
                  name="register_name"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col cols="1">
              <b-form-group
                label="Register ID"
                label-for="register_id"
              >
                <b-form-input
                  v-model="register.register_id"
                  name="register_id"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                label="Fonksiyon Kodu"
                label-for="i-center_type"
              >
                <v-select
                  id="i-center_type"
                  v-model="register.function_code"
                  :options="function_code_options"
                  label="title"
                  :clearable="false"
                >
                  <template #option="{ title, icon }" />
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                label="Byte Sıralaması"
                label-for="i-center_type"
              >
                <v-select
                  id="i-center_type"
                  v-model="register.order_by_byte"
                  :options="order_by_byte_options"
                  label="title"
                  :clearable="false"
                >
                  <template #option="{ title, icon }" />
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group
                label="Register Türü"
                label-for="i-center_type"
              >
                <v-select
                  id="i-center_type"
                  v-model="register.register_type"
                  :options="register_type_options"
                  label="title"
                  :clearable="false"
                >
                  <template #option="{ title, icon }" />
                </v-select>
              </b-form-group>
            </b-col>
            <b-col v-if="register.register_type.value=='durum'"
            >
              <b-form-group
                label="Negatif Değer"
                label-for="i-center_type"
              >
                <b-form-input
                  v-model="register.alarm_negative"
                  placeholder="Negatif Değer"
                  name="name"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="register.register_type.value=='durum'"
            >
              <b-form-group
                label="Pozitif Değer"
                label-for="i-center_type"
              >
                <b-form-input
                  v-model="register.alarm_possitive"
                  placeholder="Pozitif Değer"
                  name="name"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-button
                size="sm"
                variant="danger"
                @click="removeRegister(index)"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-row
          v-if="device_brand.value!==null"
          class="justify-content-center"
        >
          <b-col cols="3">
            <b-button
              @click="addNewRegister"
            >Yeni Satır Ekle</b-button>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mr-1"
            @click="new_device_add_button"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Ekle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->

    <!--    Update Modal-->
    <b-modal
      id="update_device_modal"
      ref="update_device_modal"
      centered
      content-class="shadow"
      footer-class="d-none"
      size="lg"
      title="Kayıt Düzenle"
    >
      <div>
        <b-row>
          <b-col
            cols="8"
          >
            <b-form-group
              label="Cihaz Türü"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="edit_devices_tpye"
                disabled="disabled"
                :options="devices_tpye_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Bağlantı Protokolü"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="edit_connection_protocol"
                disabled="disabled"
                :options="connection_protocol_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Cihaz Marka"
              label-for="i-center_type"
            >
              <v-select
                id="i-center_type"
                v-model="edit_device_brand"
                disabled="disabled"
                :options="device_brand_options"
                label="title"
                :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Cihaz Adı"
              label-for="name"
            >
              <b-form-input
                v-model="edit_device_name"
                placeholder="Cihaz Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Cihaz Seri No"
              label-for="name"
            >
              <b-form-input
                v-model="edit_device_serial_no"
                placeholder="Cihaz Seri No"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Cihaz Açıklama"
              label-for="name"
            >
              <b-form-input
                v-model="edit_device_comment"
                placeholder="Cihaz Açıklama"
                name="name"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="8"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="IP Adresi"
              label-for="ip_address"
            >
              <b-form-input
                v-model="edit_ip_address"
                name="ip_address"
                type="text"
                placeholder="192.168.25.66"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Port"
              label-for="port"
            >
              <b-form-input
                v-model="edit_port"
                name="port"
                placeholder="Port"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="edit_connection_protocol=='modbus'"
            cols="2"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Unit ID"
              label-for="unit_id"
            >
              <b-form-input
                v-model="edit_unit_id"
                name="unit_id"
                placeholder="Unit ID"
                type="number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <div>
          <b-row
            v-for="(register,index) in edit_device_registers"
            :key="index"
            class="align-items-center"
          >
            <b-col cols="2">
              <b-form-group
                label="Register Adı"
                label-for="register_name"
              >
                <b-form-input
                  v-model="register.register_name"
                  name="register_name"
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="2"
              class="px-25"
            >
              <b-form-group
                label="Register ID"
                label-for="register_id"
              >
                <b-form-input
                  v-model="register.register_id"
                  name="register_id"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              class="p-0"
              cols="4"
            >
              <b-form-group
                label="Fonksiyon Kodu"
                label-for="i-center_type"
              >
                <v-select
                  id="i-center_type"
                  v-model="register.function_code"
                  :options="function_code_options"
                  label="title"
                  :clearable="false"
                >
                  <template #option="{ title, icon }" />
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="3"
            >
              <b-form-group
                label="Byte Sıralaması"
                label-for="i-center_type"
              >
                <v-select
                  id="i-center_type"
                  v-model="register.order_by_byte"
                  :options="order_by_byte_options"
                  label="title"
                  :clearable="false"
                >
                  <template #option="{ title, icon }" />
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="1">
              <b-button
                size="sm"
                variant="danger"
                @click="removeRegister(index)"
              >
                <feather-icon icon="MinusIcon" />
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-row
          v-if="device_brand.value!==null"
          class="justify-content-center"
        >
          <b-col cols="3">
            <b-button
              @click="addNewRegister"
            >Yeni Satır Ekle</b-button>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mr-1"
            @click="device_update_button"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Güncelle</span>
          </b-button>
        </b-row>
      </div>
    </b-modal>
    <!--    Update Modal-->
    <!--    Sil Modal-->
    <b-modal
      id="delete_device_modal"
      ref="delete_device_modal"
      modal-class="modal-danger"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Kayıt Sil"
      footer-class="d-none"
      size="md"
      @hide="close_delete_device_modal"
    >
      <div>

        <b-card-text class="text-center">
          <span class="text-danger">{{ selected_row_name }}</span><span class="mx-1">isimli kaydı silmek istediğinize emin misiniz?</span>
        </b-card-text>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-success"
            class="mr-1"
            @click="close_delete_device_modal"
          >
            <feather-icon
              icon="XIcon"
              class="mr-1"
            />
            <span class="align-middle">İptal</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-danger"
            class="mr-1"
            @click="device_delete_button()"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-1"
            />
            <span class="align-middle">Sil</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Sil Modal-->
    <!--    Canlı İzle Modal-->
    <b-modal
      id="monitor_device_modal"
      ref="monitor_device_modal"
      modal-class="modal-info"
      centered
      no-close-on-backdrop
      content-class="shadow"
      title="Anlık Veri"
      footer-class="d-none"
      size="lg"
      @hide="close_monitor_device_modal"
    >
      <div style="min-height: 500px">
        <b-table
          responsive
          :items="table_data"
          :fields="tableColumns"
          primary-key="id"
          class="text-nowrap"
          show-empty
          empty-text="Veriler yükleniyor. Lütfen Bekleyin"
        />
      </div>
    </b-modal>
    <!--    Canlı İzle Modal-->
    <b-row class="justify-content-end mb-1">
      <!-- Search and add new  -->
      <b-col
        cols="12"
        md="6"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQuery"
            size="sm"
            class="d-inline-block mr-25"
            placeholder="Ara..."
          />
          <b-button
            v-if="$can('manage', 'admin')"
            v-b-modal.new_device_add_modal
            variant="primary"
            size="sm"
          >
            <span class="text-nowrap">Yeni Ekle</span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(device, index) in device_list"
        :key="index"
        class="border-warning rounded m-25"
        cols="4"
      >
        <b-card
          no-body
          class="m-0"
        >
          <b-row
            class="bg-light-primary"
            align-h="between"
          >

            <div class="text-left m-50 ">
              <div>
                <b>{{ device.devices_name }}</b>
              </div>
              <hr>
              <div>
                <b>Marka:</b> {{ device.device_brand.device_brand }}
              </div>
              <div>
                <b>Cihaz Türü:</b> {{ device.device_type.device_type_name }}
              </div>
              <div>
                <b>Bağlantı Protokolü:</b> {{ device.connection_protocol }}
              </div>
              <div>
                <b>Seri No:</b> {{ device.serial_number }}
              </div>
              <div>
                <b>Açıklama:</b> {{ device.note }}
              </div>
            </div>
            <div class="text-right m-50">
              <div>
                <b-badge
                  v-b-tooltip="'Son Okuma'"
                  class="mr-25"
                  variant="success"
                >
                  {{ new Date().toLocaleString() }} <feather-icon icon="MapPinIcon" />
                </b-badge>
                <b-badge
                  v-b-tooltip="'Anlık İzle'"
                  class="mr-25"
                  variant="info"
                  @click="rowMonitorModal(device.id)"
                >
                  <feather-icon icon="EyeIcon" />
                </b-badge>
                <b-dropdown
                  v-b-tooltip="'Seçenekler'"
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >

                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="get_device_detail(device.id, )">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Düzenle</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="rowDeleteModal(device.id)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">Sil</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-row>
          <!--                <hr>-->
          <!--                <b-row>-->
          <!--                  <b-col class="bg-light-secondary mr-25 ml-25">-->
          <!--                    <feather-icon icon="ActivityIcon" /> 0 kw-->
          <!--                  </b-col>-->
          <!--                  <b-col class="bg-light-secondary mr-25 ml-25">-->
          <!--                    <feather-icon icon="ThermometerIcon" /> 0°C-->
          <!--                  </b-col>-->
          <!--                  <b-col class="bg-light-secondary mr-25 ml-25">-->
          <!--                    <feather-icon icon="PieChartIcon" /> 0-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BBadge,
  BButton,
  BCard, BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BModal,
  BRow, BTable,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { SwiperSlide } from 'vue-awesome-swiper'

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BTable,
    BCardText,
    BDropdownItem,
    BDropdown,
    SwiperSlide,
    BModal,
    BInputGroup,
    BFormGroup,
    vSelect,
    BFormInput,
    BButton,
    BImg,
    Locale,
    BBadge,
    BCard,
    BCol,
    BRow,
  },
  props: {
    center_id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      intervalId: null,
      table_data: null,
      tableColumns: [
        { key: 'register_name', label: 'Register Adı', sortable: true },
        { key: 'register_id', label: 'ID', sortable: true },
        { key: 'function_code', label: 'Fonksiyon Kodu', sortable: true },
        { key: 'order_by_byte', label: 'Byte Sıralaması', sortable: true },
        { key: 'data', label: 'Veri', sortable: true },
      ],
      selected_row_name: null,
      selected_row_id: null,
      searchQuery: null,
      device_name: null,
      edit_device_name: null,
      device_list: [],
      device_serial_no: null,
      edit_device_serial_no: null,
      device_comment: null,
      edit_device_comment: null,
      ip_address: null,
      edit_ip_address: null,
      port: null,
      edit_port: null,
      unit_id: null,
      edit_unit_id: null,
      devices_tpye: {
        title: 'Cihaz Türü',
        value: null,
        is_active_iec104: false,
        is_active_modbus: false,
        is_active_iec61850: false,
      },
      edit_devices_tpye: {},
      devices_tpye_options: [],
      connection_protocol: { title: 'Protokol', value: null },
      edit_connection_protocol: {},
      connection_protocol_options: [],
      device_brand: { title: 'Marka', value: null },
      edit_device_brand: {},
      device_brand_options: [],
      device_registers: [{
        register_name: null,
        register_id: null,
        function_code: null,
        order_by_byte: null,
        register_type: { title: 'Register Türü', value: null },
        alarm_negative: '',
        alarm_possitive: '',
      }],
      edit_device_registers: [],
      function_code_options: [
        { title: '01 Read Coils(0x)', value: '01_read_coils' },
        { title: '02 Read Discrete Inputs(1x)', value: '02_read_discrete_inputs' },
        { title: '03 Read Holding Registers(4x)', value: '03_read_holding_registers' },
        { title: '04 Read Input Registers(3x)', value: '04_read_input_registers)' },
        // { title: '05 Write Single Coil', value: '05_write_single_coil' },
        // { title: '06 Write Single Register', value: '06_write_single_register' },
        // { title: '15 Write Multiple Coils', value: '15_write_multiple_coils' },
        // { title: '16 Write Multiple Registers', value: '16_write_multiple_registers' },
      ],
      order_by_byte_options: [
        { title: 'Signed', value: 'Signed' },
        { title: 'Unsigned', value: 'Unsigned' },
        { title: 'Hex - ASCII', value: 'Hex - ASCII' },
        { title: 'Binary', value: 'Binary' },
        { title: 'Long AB CD', value: 'Long AB CD' },
        { title: 'Long CD AB', value: 'Long CD AB' },
        { title: 'Long BADC', value: 'Long BADC' },
        { title: 'Long DC BA', value: 'Long DC BA' },
        { title: 'Float AB CD', value: 'Float AB CD' },
        { title: 'Float CD AB', value: 'Float CD AB' },
        { title: 'Float BA DC', value: 'Float BA DC' },
        { title: 'Float DC BA', value: 'Float DC BA' },
        { title: 'Double AB CD EF GH', value: 'Double AB CD EF GH' },
        { title: 'Double GH EF CD AB', value: 'Double GH EF CD AB' },
        { title: 'Double BA DC FE HG', value: 'Double BA DC FE HG' },
        { title: 'Double HG FE DC BA', value: 'Double HG FE DC BA' },
      ],
      register_type_options: [
        { title: 'Analog', value: 'analog' },
        { title: 'Alarm', value: 'alarm' },
        { title: 'Durum', value: 'durum' },
      ],
    }
  },
  watch: {
    devices_tpye: {
      handler() {
        this.connection_protocol_options = []
        this.connection_protocol = { title: 'Protokol', value: null }
        if (this.devices_tpye.is_active_iec104) { this.connection_protocol_options.push({ title: 'IEC-104', value: 'iec104' }) }
        if (this.devices_tpye.is_active_modbus) { this.connection_protocol_options.push({ title: 'MODBUS', value: 'modbus' }) }
        if (this.devices_tpye.is_active_iec61850) { this.connection_protocol_options.push({ title: 'IEC-61850', value: 'iec61850' }) }
        this.getDeviceBrand()
      },
    },
    device_brand: {
      handler() {
        this.getRegisterTemplate()
      },
    },
  },
  created() {
    this.getDevicetypes()
    this.getDevices()
  },
  methods: {
    getRegisterTemplate() {
      if (this.device_brand.value) {
        useJwt.getDeviceBrandRegisterTemplatesList({
          // center_type: this.filter_center_type.value,
          device_brand: this.device_brand.value,
          page_size: 500,
        })
          .then(response => {
            if (response.data.count != 0) {
              const temp_list = []
              response.data.results.forEach(ddd => {
                let v_function_code = null
                let v_order_by_byte = null
                let v_register_type = null
                this.function_code_options.forEach(mmm => {
                  if (mmm.value == ddd.function_code){
                    v_function_code = mmm
                  }
                })
                this.order_by_byte_options.forEach(mmm => {
                  if (mmm.value == ddd.order_by_byte){
                    v_order_by_byte = mmm
                  }
                })
                this.register_type_options.forEach(mmm => {
                  if (mmm.value == ddd.register_type){
                    v_register_type = mmm
                  }
                })
                temp_list.push({
                  register_name: ddd.register_name,
                  register_id: ddd.register_id,
                  function_code: v_function_code,
                  order_by_byte: v_order_by_byte,
                  register_type: v_register_type,
                  alarm_negative: ddd.alarm_negative,
                  alarm_possitive: ddd.alarm_possitive,
                })
              })
              this.device_registers = temp_list
            }
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    },
    getCompanyCenterDevicesMonitorData() {
      if (this.selected_row_id != null) {
        const formData = new FormData()
        formData.append('device_id', this.selected_row_id)
        useJwt.companycenterdevicesmonitor(formData)
          .then(response => {
            this.table_data = response.data
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    },
    getDevices() {
      useJwt.getCompanyCenterDevicesList({
        page_size: 5000,
        companycenter: this.center_id,
      })
        .then(response => {
          this.device_list = response.data.results
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    removeRegister(index) {
      if (index > -1) {
        this.device_registers.splice(index, 1)
      }
    },
    addNewRegister() {
      this.device_registers.push({
        register_name: null,
        register_id: null,
        function_code: null,
        order_by_byte: null,
        register_type: { title: 'Register Türü', value: null },
        alarm_negative: '',
        alarm_possitive: '',
      })
    },
    getDeviceBrand() {
      useJwt.getDeviceBrandList({
        // center_type: this.filter_center_type.value,
        device_type: this.devices_tpye.value,
        page_size: 5000,
      })
        .then(response => {
          this.device_brand = { title: 'Marka', value: null }
          this.device_brand_options = []
          response.data.results.forEach(data => {
            this.device_brand_options.push({
              title: data.device_brand,
              value: data.id,
            })
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getDevicetypes() {
      useJwt.getDeviceTypeList({
        page_size: 5000,
      })
        .then(response => {
          response.data.results.forEach(data => {
            this.devices_tpye_options.push({
              title: data.device_type_name,
              value: data.id,
              is_active_iec104: data.is_active_iec104,
              is_active_modbus: data.is_active_modbus,
              is_active_iec61850: data.is_active_iec61850,
            })
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    new_device_add_button() {
      const formData = new FormData()
      formData.append('companycenter', this.center_id)
      formData.append('device_type', this.devices_tpye.value)
      formData.append('connection_protocol', this.connection_protocol.value)
      formData.append('device_brand', this.device_brand.value)
      formData.append('devices_name', this.device_name)
      formData.append('serial_number', this.device_serial_no)
      formData.append('note', this.device_comment)
      formData.append('ip_address', this.ip_address)
      formData.append('device_port', this.port)
      formData.append('unit_id', this.unit_id)
      formData.append('register_data', JSON.stringify(this.device_registers))

      useJwt.createCompanyCenterDevices(formData)
        .then(response => {
          this.getDevices()
          this.close_new_device_add_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Hata',
              icon: 'AlertIcon',
              variant: 'danger',
              text: error.response.data.detail,
            },
          })
          console.log(error.response)
        })
    },
    get_device_detail(device_id) {
      this.selected_row_id = device_id
      useJwt.detailCompanyCenterDevices(device_id)
        .then(response => {
          console.log(response.data)
          this.edit_devices_tpye = response.data.device_type.device_type_name
          this.edit_connection_protocol = response.data.connection_protocol
          this.edit_device_brand = response.data.device_brand.device_brand
          this.edit_device_name = response.data.devices_name
          this.edit_device_serial_no = response.data.serial_number
          this.edit_device_comment = response.data.note
          this.edit_ip_address = response.data.ip_address
          this.edit_port = response.data.device_port
          this.edit_unit_id = response.data.unit_id
          this.$refs.update_device_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    device_update_button() {

    },
    rowDeleteModal(device_id) {
      this.selected_row_id = device_id
      useJwt.detailCompanyCenterDevices(device_id)
        .then(response => {
          this.selected_row_name = response.data.devices_name
          this.selected_row_id = response.data.id
          this.$refs.delete_device_modal.show()
        })
        .catch(error => {
          console.log(error)
        })
    },
    rowMonitorModal(device_id) {
      this.selected_row_id = device_id
      this.getCompanyCenterDevicesMonitorData()
      this.intervalId = setInterval(this.getCompanyCenterDevicesMonitorData, 3000)
      this.$refs.monitor_device_modal.show()
    },
    device_delete_button() {
      useJwt.deleteCompanyCenterDevices(this.selected_row_id)
        .then(response => {
          this.getDevices()
          this.close_delete_device_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_device_add_modal() {
      this.$refs.new_device_add_modal.hide()
    },
    close_delete_device_modal() {
      this.$refs.delete_device_modal.hide()
    },
    close_monitor_device_modal() {
      this.selected_row_id = null
      clearInterval(this.intervalId)
      this.$refs.monitor_device_modal.hide()
    },
  },
}
</script>

<style lang="scss">

</style>
